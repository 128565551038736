<template>
  <div class="row align-items-center">
    <div class="col-12">
      <h3 class="mb-2">{{ $t("templateList") }}</h3>
    </div>
  </div>


  <div class="templates-content d-flex flex-wrap justify-content-around" id="scrollComponent">
    <div class="template-card" v-for="template in mailsTemplatesData.data" :key="template.id">
      <img v-if="template.template.image_header.url" :src="template.template.image_header.url" class="template-card__image" :alt="$t('image')">
      <img v-else src="/img/brand/default-placeholder.png" class="template-card__image" :alt="$t('image')">
      <div class="template-card__header">
          <p class="text-ellipsis">{{template.name}}</p>
      </div>
      <div class="template-card__body">
        <strong>{{template.template.title.text}}</strong>

        <div class="btn-group template-button">
          <button type="button" class="btn btn-default btn-action" @click="showTemplate(template)">
            {{ $t("preview") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- </card> -->
  <modal
    v-model:show="showPreview"
    :showClose="false"
    header-classes="pb-1"
    body-classes="pt-1"
  >
    <template v-slot:header>
    </template>
    <TemplatePreview
      v-if="templatePreview"
      :template="templatePreview"
      :isTemplate="true"
      @close="showPreview = false"
      @chooseTemplate="cloneItem($event.id)"
    />
  </modal>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElSelect,
  ElOption
} from "element-plus";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useMails from "@/composables/useMails";
import modal from '@/components/Modal';
import TemplatePreview from '@/views/Components/TemplatePreview.vue';
import { TemplateFormClass } from '@/util/Models/TemplateClass.js';

export default {
  name: "MailsTemplates",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    ElSelect,
    ElOption,
    modal,
    TemplatePreview
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const showConfirmModal = ref(false)
    const templatePreview = ref(null)
    const selectedTemplate = ref(null)
    const showPreview = ref(false)

    const { mailsTemplatesData, getMailsTemplates, cloneMailTemplate } = useMails();
    const mailIdToManage = ref(null)
    const modalConfirm = ref({
      title: null,
      subtitle: null,
      textCancel: null,
      textConfirm: null,
      confirmAction: null,
      cancelAction: null,
      allowClose:false,
    });
    const isLoading = ref(false)
    const scrollComponent = ref(null);

    const listMailsTemplates = async (page = 1) => {
      await getMailsTemplates(page);
    }

    const editMail = (id, saveFilter = true) => {
      if(saveFilter){
        localStorage.setItem('mailsFilters', JSON.stringify({page: mailsTemplatesData.value.current_page}))
      }
      router.push({ name: 'EditMail', params: { id: id } });
    }
    const cloneItem = async (id) => {
      showPreview.value = false;
      const response = await cloneMailTemplate(id)
      if (response && response.status === 200) {
        editMail(response.data.id, false)
      }
    }

    const showSent = async (id, saveFilter = true) => {
      if(saveFilter){
        localStorage.setItem('mailsFilters', JSON.stringify({page: mailsTemplatesData.value.current_page}))
      }
      router.push({ name: 'EmailsSent', params: { id: id } });
    }

    const showTemplate = (template) => {
      if(template){
        showPreview.value = true
        templatePreview.value = new TemplateFormClass(template)
      }
      selectedTemplate.value = template ? template : null;
    }

    onMounted( () => {
      listMailsTemplates(mailsTemplatesData.value.current_page);
    })

    return {
      mailsTemplatesData,
      showConfirmModal,
      modalConfirm,
      isLoading,
      scrollComponent,
      editMail,
      cloneItem,
      listMailsTemplates,
      showSent,
      selectedTemplate,
      showPreview,
      showTemplate,
      templatePreview,
      showTemplate
    };
  },
}
</script>

<style scoped lang="scss">
.el-table_1_column_4 th {
  text-align: right;
}
:deep() .el-table__body-wrapper{
  min-height: 350px!important;
}
.min-table{
  min-height: 450px;
  max-width: 99%!important;
}
.dropdown-item{
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #16181b!important;
    background-color: #f6f9fc!important;
  }
}

.template-card{
  margin: 0 10px 50px 10px;
  width: 258px;
  height: 377px;
  border-radius: 20px;
  box-shadow: 0px 15px 35px rgba(3,115,168,0.1), 0px 20px 10px rgba(3,115,168,0.1);
  p{
    margin: 0;
  }
  &__image{
    width: 100%;
    height: 177px;
    min-height: 177px;
    object-fit: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  &__header{
    height: 33px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    text-transform: uppercase;
    color: #ffffff;
    p{
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__body{
    padding: 13px;
    padding-bottom: 29px;
    height: 167px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
      font-size: 13px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1em;
    }
    .template-button{
      margin-top: auto;
    }

    strong {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:nth-child(1n) .template-card__header{
    background-color: #012454;
  }
  &:nth-child(2n) .template-card__header{
    background-color: #0373a8;
  }
  &:nth-child(3n) .template-card__header{
    background-color: #8dfd36;
  }
  .template-height{
    height: 377px;
  }

  .btn-group{
    .btn-action {
      height: 38px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}
</style>
